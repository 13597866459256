import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: #f9f1ea;

  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ theme }) => theme.breakpoints.down("xs")} {
      text-align: center;
      ul {
        list-style: none;
      }
    }
  }

  ${props =>
    props.scrollSnap
      ? `
      padding-top: 0;
      padding-bottom: 0;
      scroll-snap-align: start;
      scroll-snap-stop: always;
      
      & > .container {
        height: 100vh;
      }`
      : ""}

  ${({ theme }) => theme.breakpoints.down("xs")} {
    ${props =>
      props.scrollSnap
        ? `
        padding-top: 5rem;
        padding-bottom: 5rem;
        
        & > .container {
          height: unset;
        }`
        : ""}
  }

  .title {
    font-size: ${({ theme }) => theme.typography.pxToRem(56)};
    margin: 4rem 0 20px;
    line-height: 1.1em;
    font-weight: 400;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      margin-bottom: 5px;
      font-size: ${({ theme }) => theme.typography.pxToRem(34)};
    }
  }

  .content p,
  .content li {
    margin-top: 0;
    font-weight: strong;
    font-family: ${({ theme }) => theme.fonts.tertiary};
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  }

  .buttons .custom-button:not(:last-child) {
    margin-right: 1rem;
  }

  figure {
    margin: 0;

    blockquote {
      font-family: ${({ theme }) => theme.fonts.tertiary};
      font-size: ${({ theme }) => theme.typography.pxToRem(24)};
      line-height: 1.2em;
      margin: 0 0 20px;
    }

    cite {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-size: ${({ theme }) => theme.typography.pxToRem(24)};
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .slick-slider {
    padding-bottom: 40px;

    .slick-prev,
    .slick-next {
      top: unset;
      bottom: 0;
      width: 100px;
      height: 30px;
      background: url("/img/arrow-straight.svg") 0 0 no-repeat;
      background-size: contain;
      transform: unset;

      &::before {
        display: none;
      }
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
      transform: scale(-1, 1);
    }
  }
`
