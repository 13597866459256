import styled from "styled-components"
import Container from "@material-ui/core/Container"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  position: relative;
  padding: 0;

  .gatsby-image-wrapper {
    height: 80vh;
  }

  ${props =>
    props.scrollSnap
      ? `
      scroll-snap-align: start;
      scroll-snap-stop: always;
      
      .gatsby-image-wrapper {
        height: 100vh !important;
      }`
      : ""}

  ${({ theme }) => theme.breakpoints.down("xs")} {
    ${props =>
      props.scrollSnap
        ? `

        .gatsby-image-wrapper {
          height: unset;
        }`
        : ""}
  }

  .featured {
    .content {
      width: 45%;
      p,
      li {
        font-family: ${({ theme }) => theme.fonts.tertiary};
        line-height: 1.4em;
      }
    }

    h2 {
      font-size: ${({ theme }) => theme.typography.pxToRem(56)};
      margin: 0;
      line-height: 1em;
      font-weight: 400;
    }

    ${({ theme }) => theme.breakpoints.down("xs")} {
      .content {
        p,
        li {
          font-size: ${({ theme }) => theme.typography.pxToRem(17)};
        }
        width: 100%;
      }
    }
  }

  .key_points_title {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(36)};
    line-height: 1.2em;
    margin: 0;

    ${({ theme }) => theme.breakpoints.down("xs")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    }

    &::after {
      content: "";
      display: block;
      height: 3px;
      background: linear-gradient(to right, #799fdf 0, #50d9b5 59%, #80dc94);
      margin-top: 20px;
    }
  }

  .key_points {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    & > .item {
      padding: 0 0.5em;
      flex-basis: calc(20% - 1em);
      margin-top: 1em;
      font-weight: 700;
      font-family: ${({ theme }) => theme.fonts.tertiary};
      font-size: ${({ theme }) => theme.typography.pxToRem(16)};
      text-transform: uppercase;
      line-height: 1.4em;

      ${({ theme }) => theme.breakpoints.down("xs")} {
        flex-basis: 50%;
        &:last-child {
          flex-grow: 1;
        }
      }
    }

    ${({ theme }) => theme.breakpoints.down("xs")} {
      justify-content: center;
    }
  }
`
export const FloatingContainer = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

export const InteriorContainer = styled(Container)`
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5rem;
  padding-bottom: 5rem;
  color: #fff;

  ${props =>
    props.scrollSnap
      ? `
      justify-content: center;
      `
      : ""}

  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding-top: 2rem;
    padding-bottom: 2rem;

    ${props =>
      props.scrollSnap
        ? `
        justify-content: space-around;
        `
        : ""}
  }
`
