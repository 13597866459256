import React from "react"
import * as S from "./joy-love-jove.styles.jsx"
import parse from "html-react-parser"
import { useTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CustomImage from "../../components/custom-image/custom-image.component"
import JoyLoveGif from "../../images/joy-love-jove.gif"

const JoyLoveJove = data => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  return (
    <S.Wrapper scrollSnap={data.scrollSnap}>
      <CustomImage
        className="image"
        img={isMobile ? data.background.mobile : data.background.desktop}
      />
      <S.FloatingContainer>
        <S.InteriorContainer scrollSnap={data.scrollSnap}>
          <div className="featured">
            <div className="content">
              {data.title && (
                <>
                  <h2>{parse(data.title)}</h2>
                  <img src={JoyLoveGif} alt="Joy, Love, Jove" width="250" />
                </>
              )}

              {data.content && (
                <div className="description">{parse(data.content)}</div>
              )}
            </div>
          </div>

          <div className="keypoints">
            {data.keyPointsTitle && (
              <h4 className="key_points_title">{parse(data.keyPointsTitle)}</h4>
            )}

            {data.keyPoints && (
              <div className="key_points">
                {data.keyPoints.map((item, index) => (
                  <div className="item" key={index}>
                    {parse(item.keyPoint)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </S.InteriorContainer>
      </S.FloatingContainer>
    </S.Wrapper>
  )
}
export default JoyLoveJove
