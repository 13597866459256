import React from "react"
import * as S from "./quad-grid.styles.jsx"
import parse from "html-react-parser"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const QuadGrid = ({ items, title, subtitle, scrollSnap }) => {
  if (!items) return null

  var sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <S.Wrapper contained scrollSnap={scrollSnap}>
      {title && <h3>{parse(title)}</h3>}

      {subtitle && <S.Subtitle>{parse(subtitle)}</S.Subtitle>}

      {items && (
        <div className="items">
          <Slider {...sliderSettings}>
            {items.map((item, index) => (
              <div className="item" key={index}>
                {item.image && (
                  <S.IconContainer>
                    <S.Icon alt={item.image.alt} img={item.image} />
                  </S.IconContainer>
                )}

                {item.title && <h3 className="title">{parse(item.title)}</h3>}
                {item.description && (
                  <div className="description">{parse(item.description)}</div>
                )}
              </div>
            ))}
          </Slider>
        </div>
      )}
    </S.Wrapper>
  )
}
export default QuadGrid
