import styled from "styled-components"
import Container from "@material-ui/core/Container"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled.div`
  position: relative;

  ${props =>
    props.scrollSnap
      ? `
      padding-top: 0;
      padding-bottom: 0;
      scroll-snap-align: start;
      scroll-snap-stop: always;
      height: 100vh;
      & > div {
        height: 100vh;
      }
      .gatsby-image-wrapper {
        height: 100vh;
      }
      `
      : ""}

  .gatsby-image-wrapper {
    max-height: 100vh;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    // height: 100vh;
    // max-height: 100vh;
  }
`

export const FloatingContainer = styled(Container)`
  position: absolute;
  z-index: 10;
  top: var(--header-height);
  left: 50%;
  transform: translateX(-50%);
  height: calc(90% - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    top: unset;
    bottom: 60px;
    height: calc(100vh - var(--header-height));
    justify-content: flex-end;
  }

  .title {
    font-family: ${({ theme }) => theme.fonts.primary};
    margin: 0 0 1rem;
    font-weight: 400;
    font-size: ${({ theme }) => theme.typography.pxToRem(51)};
    line-height: 51px;
    ${({ theme }) => theme.breakpoints.up("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(70)};
      line-height: 75px;
    }

    ${({ theme }) => theme.breakpoints.only("xs")} {
      font-size: 2.5rem;
      line-height: 1.1em;
    }
  }

  .buttons .custom-button {
    margin-bottom: 10px;
  }
  .buttons .custom-button:not(:last-child) {
    margin-right: 1rem;
  }
`

export const Video = styled.video`
  width: 100%;
`
export const Image = styled(CustomImage)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    // height: 100%;
  }
`

export const ContentArea = styled.div`
  width: 48%;
  max-width: 500px;
  color: #fff;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: auto;
    max-width: unset;
  }

  sup {
    font-size: 0.5em;
    top: -0.1em;
  }
`
export const Content = styled.div`
  font-weight: 400;
  text-transform: uppercase;
  p {
    font-family: ${({ theme }) => theme.fonts.primary};
  }
`
