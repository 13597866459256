import React from "react"
import * as S from "./promo-section.styles.jsx"
import parse from "html-react-parser"
import { useTheme } from "@material-ui/core"
import GradientRoundedButton from "../../components/gradient-rounded-button/gradient-rounded-button.component.jsx"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const PromoSection = data => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <S.Wrapper
      scrollSnap={data.scrollSnap}
      className={data.applyMask ? "masked" : ""}
    >
      {data.desktop.videoBackground ? (
        <S.Video
          src={
            isMobile
              ? data.mobile.videoBackground.publicUrl
              : data.desktop.videoBackground.publicUrl
          }
          poster={
            isMobile
              ? data.mobile.backgroundImage?.publicUrl
              : data.desktop.backgroundImage?.publicUrl
          }
          playsInline
          muted
          autoPlay
          loop
        ></S.Video>
      ) : (
        <S.Image
          className="image"
          img={
            isMobile
              ? data.mobile.backgroundImage
              : data.desktop.backgroundImage
          }
        />
      )}
      <S.FloatingContainer>
        <S.ContentArea>
          <p className="title">{parse(data.title)}</p>
          {data.content && <S.Content>{parse(data.content)}</S.Content>}
          {data.buttons && data.buttons.length && (
            <div className="buttons">
              {data.buttons.map((button, index) => (
                <GradientRoundedButton
                  key={index}
                  target={button.button.target}
                  href={button.button.url}
                >
                  {button.button.title}
                </GradientRoundedButton>
              ))}
            </div>
          )}
        </S.ContentArea>
      </S.FloatingContainer>
    </S.Wrapper>
  )
}
export default PromoSection
