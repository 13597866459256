import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import parse from "html-react-parser"
import * as S from "./featured-testimonials-section.styles.jsx"
import MaskedMedia from "../../components/masked-media/masked-media.component.jsx"
import { Grid } from "@material-ui/core"

const FeaturedTestimonialsSection = data => {
  var sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  }
  return (
    <>
      <S.Wrapper
        contained
        scrollSnap={data.scrollSnap}
        containerClassName={"container " + (data.flip ? "flip" : "")}
      >
        <Grid container spacing={5}>
          <Grid className="copy" item sm={6} xs={12} alignItems="center">
            {data.title && <h3 className="title">{parse(data.title)}</h3>}

            {data.testimonials && (
              <div className="testimonials">
                <Slider {...sliderSettings}>
                  {data.testimonials.map((item, index) => (
                    <figure className="testimonial" key={index}>
                      {item.testimonial && (
                        <blockquote>{item.testimonial}</blockquote>
                      )}
                      {item.name && <cite>{item.name}</cite>}
                    </figure>
                  ))}
                </Slider>
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <MaskedMedia media={data.media} />
          </Grid>
        </Grid>
      </S.Wrapper>
    </>
  )
}
export default FeaturedTestimonialsSection
